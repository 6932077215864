@import "shared/styles/media.scss";

.order_review_card {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  width: 100%;

  .image {
    position: relative;
    flex-shrink: 0;

    border-radius: 20px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    .image_inner {
      position: relative;
      width: 130px;
      padding-bottom: 64%;

      img {
        object-fit: contain;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: calc(100% - (130px + 30px));

    .content_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      width: 100%;

      .title {
        color: #000;

        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        text-align: left;

        width: calc(100% - 20px);

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .status {
      font-size: 14px;
      font-weight: 600;
      line-height: 19.6px;
      text-align: left;
      color: hsla(0, 0%, 0%, 1);
    }
  }
}

.points_text {
  font-family: var(--font);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */

  background: var(--point-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
