@import "shared/styles/media.scss";

.order_review_popup {
  display: flex;
  max-width: 410px;
  width: 100%;
  padding: 20px 15px;
  flex-direction: column;
  align-items: flex-start;

  background: #fff;
  box-shadow: -10px 0px 44px 0px hsla(0, 0%, 0%, 0.25);

  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;

  @include md {
    min-width: 100%;
    max-width: 100%;
    border-radius: 20px 20px 0 0;
    bottom: 0;
    right: 0;
  }

  .header {
    display: flex;
    width: 100%;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      color: hsla(0, 0%, 55%, 1);

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  .title {
    width: 100%;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
}
